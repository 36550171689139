import React, { useState } from "react";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";

import facebook from "../images/facebook.svg";
import instagram from "../images/instagram.svg";

const Footer = styled.footer`
  width: 100%;
  background: #001336;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 2em 0;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 1em 0;
  }
  h3 {
    padding: 0;
    @media (max-width: 768px) {
      font-size: 1em;
    }
    a {
      color: white;
      transition: 0.3s;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  small {
    color: white;
    @media (max-width: 768px) {
      text-align: center;
      margin-bottom: 2em;
    }
    a {
      color: white;
      transition: 0.3s;
      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

const Form = styled.form`
  @media (max-width: 768px) {
    margin-bottom: 2em;
  }
  p {
    padding-bottom: 0.5em;
    color: white;
  }
  div {
    display: flex;
    flex-wrap: wrap;
    input {
      margin-right: 0.5em;
    }
    button {
      background: #c25803;
      border: unset;
      padding: 1em;
      color: white;
      transition: 0.3s;
      &:hover {
        background: black;
        color: white;
      }
    }
  }
  .message {
    flex-basis: 100%;
    color: white;
  }
`;

const Socials = styled.ul`
  display: flex;
  list-style: none;
  li {
    padding: 0 1em;
    &:last-child {
      padding-right: 0;
    }
    a {
      transition: 0.3s;
      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

const Wave = styled.svg`
  display: block;
  background: #f7faf8;
  margin-top: -4em;
  margin-bottom: -10px;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const WebsiteFooter = () => {
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    addToMailchimp(email)
      .then((data) => {
        console.log(data);
        setMsg(data.msg);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <>
      <Wave xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#001336"
          fillOpacity="1"
          d="M0,224L80,192C160,160,320,96,480,96C640,96,800,160,960,170.7C1120,181,1280,139,1360,117.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        />
      </Wave>
      <Footer>
        <FooterContainer className="container">
          <Form onSubmit={handleSubmit}>
            <p>Sign up to our newsletter</p>
            <div>
              <input
                placeholder="Email address"
                name="email"
                type="text"
                onChange={handleEmailChange}
              />
              <button type="submit">Subscribe</button>
              {msg ? (
                <p
                  className="message"
                  dangerouslySetInnerHTML={{ __html: msg }}
                />
              ) : null}
            </div>
          </Form>
          <ContactInfo>
            <h3>
              <a href="mailto:zara@theantiquemarketingcompany.co.uk?subject=Enquiry">
                zara@theantiquemarketingcompany.co.uk
              </a>
            </h3>
            <h3>
              <a href="tel:07828494219">07828 494219</a>
            </h3>
          </ContactInfo>
        </FooterContainer>
        <FooterContainer className="container">
          <Socials>
            <li>
              <a
                href="https://www.facebook.com/theantiquemarketingcompany"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/theantiquemarketingcompany/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="Instagram Icon" />
              </a>
            </li>
          </Socials>
        </FooterContainer>
      </Footer>
    </>
  );
};

export default WebsiteFooter;
